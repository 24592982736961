import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Spoiler } from "../../components/Spoiler";
import { Callout } from "../../components/Callout";
import { Footer, Source, SourceRef } from "../../components/Footer";
export const _frontmatter = {
  "title": "The State monad",
  "subtitle": "A thorough guide to fp-ts's State monad.",
  "date": "2019-09-18T00:00:00.000Z",
  "layout": "post",
  "draft": false,
  "tags": ["javascript", "typescript", "state", "monad", "functional programming"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`How do we manage a value that may change over time in a pure, functional application?
Let’s start with a simple example; pseudo random number generators.`}</p>
    <h2>{`Pure Random Number Generator`}</h2>
    <p>{`Generating random numbers In Javascript is simple with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Math.random()`}</code>{`. Unfortunately, this function has a few properties that aren’t conducive to a pure, functional program. For example, the values returned from `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Math.random`}</code>{` aren’t repeatable.
How would we build a function that enables generating seemingly random values, but still be repeatable? One approach is to use a technique called `}<em parentName="p">{`pseudo random number generators`}</em><SourceRef number={1} mdxType="SourceRef" />{` (or, PRNGs, for short).`}</p>
    <p>{`The basic idea with PRNGs is that you start with one number, called a `}<em parentName="p">{`seed`}</em>{`, pass it to a "generator" function to get the next number. Then you use the randomly generated number as the next seed input. You repeat this process to generate as many random numbers as needed. If we were to write this imperatively, it might look like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`random`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`seed`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` nextSeed `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1839567234`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` seed `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`972348567`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`%`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8239451023`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` nextSeed`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` seed `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` randomNumber1 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`random`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`seed`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` randomNumber2 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`random`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`randomNumber1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre>{`
      `}</div>
    <p>{`Unlike `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Math.random`}</code>{`, our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`random`}</code>{` function is pure and gives exactly the same output for every input. This is useful in many cases, from being able to replicate bugs found in our applications, to being able to repeat randomly-generated test cases.`}</p>
    <p>{`Sometimes it’s useful to generate a random value of something other than just plain numbers. Let’s say we wanted to generate a number within a certain range. We can take a randomly generated value and ensure it’s in a specific range by transforming it. Let’s build a naive version which throws away the initial seed.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`randomInRange`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`seed`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` min`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` max`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` nextSeed `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`random`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`seed`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` random `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` min `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` Math`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`floor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`nextSeed `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{` m`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`max `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{` min`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` random`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`And we can use it to generate two numbers in a range:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` seed `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` random1 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`randomInRange`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`seed`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` random2 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`randomInRange`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`seed`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre>{`
      `}</div>
    <p>{`But, oh no! The two random numbers are the same! It’s because we’ve re-used the same seed value twice. We need to return the ranged number, but we also need to return the next seed. Typescript has a notion of "tuples" which we can use to return multiple values. Let’s change our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`randomInRange`}</code>{` function to return both the next seed and the generated range number:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`randomInRange`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`seed`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` min`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` max`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` nextSeed `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`random`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`seed`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` random `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` min `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` Math`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`floor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`nextSeed `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{` m`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`max `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{` min`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`nextSeed`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` random`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`Now we can use each new seed value to generate different ranged values:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` seed1 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`seed2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` random1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`randomInRange`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`seed1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`seed3`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` random2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`randomInRange`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`seed2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre>{`
      `}</div>
    <p>{`We have two different numbers!`}</p>
    <p>{`Now, let’s build a function that generates a random first name for a user. We can start with a list of names in an array, and randomly pick one:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` FirstNames `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'Paul'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Nicole'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Zane'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Ellie'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`randomFirstName`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`seed`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`seed2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` ranged`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`randomInRange`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`seed`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` FirstNames`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`length`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`seed2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` FirstNames`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`ranged`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`You might have started to notice a pattern with these generators, all of them take a number, and then return a number, in addition to another generated value. They all have a similar shape; `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`(seed: number) => [number, A]`}</code>{`, where `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`A`}</code>{` is the generated value.`}</p>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`State`}</code>{` monad from the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`fp-ts`}</code>{` library generalizes this pattern, which means it makes it possible to use with `}<em parentName="p">{`any`}</em>{` type of state (not just `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`number`}</code>{`).`}</p>
    <h2>{`The State type`}</h2>
    <p>{`The more we use our generator functions, the more cumbersome it becomes to keep track of the current seed value, feeding it into each subsequent generator. The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`State`}</code>{` monad can help us by maintaining this value for us. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`State`}</code>{` is defined as a function that takes in some state value, and returns the next state, along with another value.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` State`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`S`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`state`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token constant"
            }}>{`S`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`S`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre>{`
      `}</div>
    <p>{`In our case, the only state we have is a number (the seed), so our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`S`}</code>{` type will be `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`number`}</code>{`, and our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`A`}</code>{` type will be different for every generator (depending on the type of value that’s generated). Let’s make a type alias for our generators:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` Random`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`state`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`number`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre>{`
      `}</div>
    <p>{`Since we already know the type of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`S`}</code>{`, we can fill it in here (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`number`}</code>{`), while leaving the return value (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`A`}</code>{`) generic. The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`A`}</code>{` value will be the type of the value we’re generating, for example, a function that generates a number will have the type `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Random<number>`}</code>{`, and a function that generates a random string will have the type `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Random<string>`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` random`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Random`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`number`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`     `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// (seed: number) => [number, number]`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` randomName`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Random`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// (seed: number) => [number, string]`}</span></code></pre>{`
      `}</div>
    <p>{`Note that we could have defined our type like: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`type Random<A> = State<number, A>`}</code>{`, which is equivalent. In Typescript, types are structural rather than nominal, so we don’t specifically need to use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`fp-ts`}</code>{`’s `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`State`}</code>{` type alias.`}</p>
    <p>{`Let’s refactor our initial function to use the new type alias:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` random`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Random`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`number`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`seed`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` nextSeed `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1839567234`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` seed `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`972348567`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`%`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8239451023`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`nextSeed`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` nextSeed`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <h3>{`map`}</h3>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`fp-ts`}</code>{`’s state comes with a handy `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`map`}</code>{` function which we can use to modify the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`A`}</code>{` inside of any `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Random<A>`}</code>{`. Here, we’ll redefine our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`randomInRange`}</code>{` to use map to modify the original random number to ensure it’s in the specified range.`}</p>
    <p>{`Here's the type signature of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`map`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` map`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`f`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`a`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token constant"
            }}>{`A`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`E`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`fa`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` State`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token constant"
            }}>{`E`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token constant"
            }}>{`A`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` State`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`E`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span></code></pre>{`
      `}</div>
    <p>{`this function is curried, so we'll invoke it like: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`map(???)(???)`}</code>{`. The first argument is a function that modifies the random output of the second argument. Let's fill in the second part first:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` random`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Random`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`number`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`randomInRange`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`max`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` min`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Random`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`number`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`random`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`Since the type inside our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`random`}</code>{` value is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`number`}</code>{`, the function we'll pass takes a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`number`}</code>{` as it's parameter, and returns some other value.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`randomInRange`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`max`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` min`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Random`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`number`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`inRange`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`num`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// change num so that it's between max and min`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` min `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` Math`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`floor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`num `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8239451023`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`max `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{` min`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`inRange`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`random`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`Here, the random number generated from `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`random`}</code>{` is passed into `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`inRange`}</code>{` supplied to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`map`}</code>{`. The map function takes care of "unwrapping" the random value inside, we don’t need to explicitly track the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`seed`}</code>{`.`}</p>
    <p>{`Try and implement the map function:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` map`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`f`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`a`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token constant"
            }}>{`A`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`E`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`fa`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` State`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token constant"
            }}>{`E`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token constant"
            }}>{`A`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` State`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`E`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`
  `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`f`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`generate`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`seed`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// f's type is (a: A) => B`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// generate's type is (s: E) => [A, E]`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// seed's type is E`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// we need to return a [B, E]`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <Spoiler label="Click to show" mdxType="Spoiler">
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}>{`
      `}<pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` map`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="code" {...{
              "className": "token constant"
            }}>{`A`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`B`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token function-variable function"
            }}>{`f`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token parameter"
            }}>{`a`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token constant"
              }}>{`A`}</span></span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`B`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="code" {...{
              "className": "token constant"
            }}>{`E`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token parameter"
            }}>{`fa`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` State`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token constant"
              }}>{`E`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="span" {...{
                "className": "token constant"
              }}>{`A`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` State`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="code" {...{
              "className": "token constant"
            }}>{`E`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`B`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{`
  `}<span parentName="code" {...{
              "className": "token parameter"
            }}>{`f`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token parameter"
            }}>{`generate`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token parameter"
            }}>{`seed`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`a`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` nextSeed`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`generate`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`seed`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
    `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`f`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`a`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` nextSeed`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code></pre>{`
      `}</div>
    </Spoiler>
    <p>{`Notice that when we use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`map`}</code>{`, we need to annotate the argument type of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`inRange`}</code>{`. If we left that out, Typescript would automatically infer the argument as any, and it would be unsafe. This is because in TS, inference is resolved left-to-right. When resolving types, Typescript first sees `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`map(num => ...)`}</code>{`, and attempts to fill in the types for use before moving on. Of course, we can see that eventually we'll be passing a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Random<number>`}</code>{`, and so `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`num`}</code>{`'s type will be `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`number`}</code>{`, but Typescript can't make that guess.`}</p>
    <h3>{`pipe`}</h3>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`fp-ts`}</code>{` provides the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pipe`}</code>{` function to alleviate some of these pains. With `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pipe`}</code>{`, you supply the object being acted on first, then supply all subsequent transformations. This allows TS to be able to infer much more:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`randomInRange`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`min`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` max`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` Random`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`number`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`pipe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    generate`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`num`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// change num so that it's between max and min`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` min `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` Math`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`floor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`num `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8239451023`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`max `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{` min`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`Here, the only types we’ve supplied are the argument types for the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`randomInRange`}</code>{` function, and TS was able to infer the rest. If you’re familiar with the fantasy-land interfaces, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pipe`}</code>{` is a good analog to that, instead of:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}>{`random
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`chain`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`We have:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`pipe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  random`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`chain`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <Callout mdxType="Callout">
      <p>{`An Ecmascript propsal`}<SourceRef number={2} mdxType="SourceRef" />{` exists for the pipeline operator, a special operator that makes working with chains of operations much easier:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}>{`
      `}<pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}>{`random
  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`map`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`...`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`chain`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`...`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span></code></pre>{`
      `}</div>
      <p>{`Whlie the pipeline operator is available via some babel plugins, Typescript does not yet support it. Until then, we have `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`pipe`}</code></p>
    </Callout>
    <p>{`We don’t need to keep the same type of values inside our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Random`}</code>{` type. In fact, in order to implement our name generator, we’ll need to take a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Random<number>`}</code>{` and turn it into a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Random<string>`}</code>{`. The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`map`}</code>{` function is generalized enough to enable this: `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` FirstNames `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'Paul'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Nicole'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Zane'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Ellie'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` randomFirstName `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`pipe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`generateInRange`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` FirstNames`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`length `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`index`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` FirstNames`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`index`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`We’ll also add a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`randomLastName`}</code>{` to generate surnames:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` LastNames `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'Gray'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Smith'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Jones'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Williams'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` randomLastName `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`pipe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`nextInRange`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` LastNames`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`length `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`index`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` LastNames`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`index`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`Before moving on: can you DRY out the code that’s shared between these functions?`}</p>
    <Spoiler label="Click to show" mdxType="Spoiler">
      <p>{`Here's an approach that uses a function that randomly picks a value from an array:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}>{`
      `}<pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` randomIn `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="code" {...{
              "className": "token constant"
            }}>{`T`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token parameter"
            }}>{`arr`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token constant"
              }}>{`T`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span></span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{`
  `}<span parentName="code" {...{
              "className": "token function"
            }}>{`pipe`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`
    `}<span parentName="code" {...{
              "className": "token function"
            }}>{`nextInRange`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token number"
            }}>{`0`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` arr`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`length `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`-`}</span>{` `}<span parentName="code" {...{
              "className": "token number"
            }}>{`1`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
    `}<span parentName="code" {...{
              "className": "token function"
            }}>{`map`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token parameter"
            }}>{`index`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` arr`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`index`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span></code></pre>{`
      `}</div>
      <p>{`Now we can define our name pickers in terms of `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`pickRandom`}</code>{`:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}>{`
      `}<pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` randomFirstName `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`randomIn`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`'Paul'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Nicole'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Zane'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Ellie'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` randomLastName  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`randomIn`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`'Gray'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Smith'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Jones'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Williams'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span></code></pre>{`
      `}</div>
    </Spoiler>
    <h2>{`Composition`}</h2>
    <h3>{`sequenceT`}</h3>
    <p>{`We can use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sequenceT`}</code>{` to combine multiple generators.`}</p>
    <p>{`For instance, let's say we want to build a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`randomFullName`}</code>{` generator, which would generate a first name and lastname. We can accomplish this simply by composing our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`randomFirstName`}</code>{` generator, and our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`randomLastName`}</code>{` generator, using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sequenceT`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` state `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'fp-ts/lib/State'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` sequenceT `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'fp-ts/lib/Apply'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` randomFullname `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`pipe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sequenceT`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`randomFirstName`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` randomLastName`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`first`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` last`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`]`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token template-string"
          }}><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`first`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{` `}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`last`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <Callout mdxType="Callout">
      <p>{`Here, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`sequenceT`}</code>{` allows us to convert a `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`[Random<A>, Random<B>]`}</code>{`  into a `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`Random<[A, B]>`}</code>{`. You might recognize this signature as similar to Promise.all`}<SourceRef number={3} mdxType="SourceRef" />{` (which takes a `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`[Promise<A>, Promise<B>]`}</code>{` and turns it into a `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`Promise<[A, B]>`}</code>{`). `}</p>
      <p><code parentName="p" {...{
          "className": "language-text"
        }}>{`sequenceT`}</code>{` is a little different, in that it works for `}<em parentName="p">{`all`}</em>{` applicatives (not only Promises).`}</p>
    </Callout>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`sequenceT`}</code>{` works for any amount of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Random`}</code>{` values that we hand it. A useful property of this function is that it maintains types across tuples. So, as an example, if we had three `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Random`}</code>{` generators that generated three different types of values, and we passed all three to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sequenceT`}</code>{`, The types of each generator are persisted in the value returned:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` state `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'fp-ts/lib/State'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` sequenceT `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'fp-ts/lib/Apply'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` randomBool`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Random`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`boolean`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` randomName`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Random`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` randomCount`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Random`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`number`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` randoms `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sequenceT`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`randomBool`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` randomName`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` randomCount`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

randoms `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// type is: Random<[boolean, string, number]>`}</span></code></pre>{`
      `}</div>
    <p>{`Due to how `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sequenceT`}</code>{` is designed, all of the the types are inferred automatically!`}</p>
    <p>{`Since we combine both `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Random`}</code>{` values at the same time, we can’t use the output of one as input to the other. For that, we'll need `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`chain`}</code>{`.`}</p>
    <h3>{`chain`}</h3>
    <p>{`Let’s say we had a random generator for favorite sports teams:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` randomHockeyTeam `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`pickRandom`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Maple Leafs'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Canadiens'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Flyers'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Bruins'`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` randomFootballTeam `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`pickRandom`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Steelers'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Eagles'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Jaguars'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`Let's assume that we want each user to have a favorite sports team, but we also want each of the sports to have equal distribution among users. Since we have more hockey teams than football teams, we can’t put them all into the same pool. What we’ll do is first generate a random boolean, then, if the value is true, we’ll pick a hockey team, but if it's false, we'll pick a football team:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` randomBoolean`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Random`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`boolean`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`pipe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`generateInRange`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`n`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` n `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// return true if it is 1, false if it is 0`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` randomTeam`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Random`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`Random`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`pipe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    randomBoolean`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`bool`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` bool `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{` randomHockeyTeam `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` randomFootballTeam`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`Here, we map over the boolean value, and turn it into a random hockey team or a random football team, with equal distribution chance. But, ack! We end up with a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Random<Random<string>>`}</code>{`, when we really only want a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Random<string>`}</code>{`. Since state a monad, we can "flatten" this structure automatically. The flattening comes from the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`chain`}</code>{` function:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` chain `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'fp-ts/lib/State'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` randomTeam`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Random`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`pipe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    randomBoolean`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`chain`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`bool`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` bool `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{` randomHockeyTeam `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` randomFootballTeam`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`chain`}</code>{`'s signature is quite similar to map, except that it allows us to return a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Random<B>`}</code>{` from our "mapping" function:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` chain`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`S`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`f`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`a`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token constant"
            }}>{`A`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` State`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`S`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`ma`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` State`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token constant"
            }}>{`S`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token constant"
            }}>{`A`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` State`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`S`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span></code></pre>{`
      `}</div>
    <p>{`Take a moment and try and implement `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`chain`}</code>{`. If you get stuck, try to explain in your own words (or in your own thoughts if you're not alone) what each variable's type is, and what that type represents. For example, a value with type `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`State<number, string>`}</code>{` is a "function that takes a number, and returns the next number and a string"`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` chain`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`S`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`f`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`a`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token constant"
            }}>{`A`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` State`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`S`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`fa`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` State`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token constant"
            }}>{`S`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token constant"
            }}>{`A`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` State`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`S`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`
  `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`f`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`generate`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`seed`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// f's type is (a: A) => State<S, B>`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// generate's type is (s: S) => [A, S]`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// seed's type is S`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// we need to return a [B, S]`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <Spoiler label="Click to show" mdxType="Spoiler">
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}>{`
      `}<pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` chain`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="code" {...{
              "className": "token constant"
            }}>{`E`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`A`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`B`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token function-variable function"
            }}>{`f`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token parameter"
            }}>{`a`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token constant"
              }}>{`A`}</span></span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` State`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="code" {...{
              "className": "token constant"
            }}>{`E`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`B`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token parameter"
            }}>{`fa`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` State`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token constant"
              }}>{`E`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="span" {...{
                "className": "token constant"
              }}>{`A`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` State`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="code" {...{
              "className": "token constant"
            }}>{`E`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`B`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{`
  `}<span parentName="code" {...{
              "className": "token parameter"
            }}>{`f`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token parameter"
            }}>{`generate`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token parameter"
            }}>{`seed`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`a`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` seed2`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`generate`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`seed`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
    `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`b`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` seed3`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`f`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`a`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`seed2`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
    `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`b`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` seed3`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code></pre>{`
      `}</div>
    </Spoiler>
    <h3>{`sequenceS`}</h3>
    <p>{`Let’s try to build a user object from our randomly generated values:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` sequenceS `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'fp-ts/lib/Apply'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` generateRandomUser `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` randomName`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  age`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`randomInRange`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`18`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`100`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  favoriteTeam`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` pickRandomTeam
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`Ack! `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`randomUser`}</code>{`’s type is:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Random`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  age`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Random`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`number`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  favoriteTeam`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Random`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`This means that in order to get an actual user, we’d need to invoke each function for each attribute:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` seed1 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`seed2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` generateRandomUser`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`seed1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`seed3`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` age`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` generateRandomUser`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`age`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`seed2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`seed4`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` favoriteTeam`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` generateRandomUser`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`favoriteTeam`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`seed3`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` randomUser `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` age`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` favoriteTeam `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre>{`
      `}</div>
    <p>{`Instead, it would be much nicer if we have a value of type:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}>{`Random`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  age`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`number`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  favoriteTeam`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span></code></pre>{`
      `}</div>
    <p>{`Fortunately, since `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`State`}</code>{` is an applicative, we can compose the values of an object, while keeping the structure in tact, using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sequenceS`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` sequenceS `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'fp-ts/lib/Apply'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` state `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'fp-ts/lib/State'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` generateRandomUser `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sequenceS`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` randomName`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  age`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`nextInRange`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`18`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`100`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  favoriteTeam`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` pickRandomTeam
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`Note how `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sequenceS`}</code>{` is quite similar to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sequenceT`}</code>{`, except that `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sequenceS`}</code>{` maintains `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`State`}</code>{` values across objects (or, "structs"), whereas `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sequenceT`}</code>{` maintains type across tuples. This is the meaning behind each of the suffixes, so you can remember that `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sequenceS`}</code>{` maps `}<em parentName="p">{`structs`}</em>{`, and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sequenceT`}</code>{` maps `}<em parentName="p">{`tuples`}</em>{`.`}</p>
    <p>{`And now we can generate our random user with only a single seed:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` seed `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1337`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` randomUser `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`generateRandomUser`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`seed`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <Footer mdxType="Footer">
      <Source number={1} link="https://en.wikipedia.org/wiki/Pseudorandom_number_generator" mdxType="Source" />
      <Source number={2} link="https://github.com/tc39/proposal-pipeline-operator" mdxType="Source" />
      <Source number={3} link="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/all" mdxType="Source" />
      <p>{`Code samples demonstrated here available at: `}<a parentName="p" {...{
          "href": "https://github.com/pfgray/fp-ts-lessons/tree/master/state"
        }}>{`https://github.com/pfgray/fp-ts-lessons/tree/master/state`}</a></p>
    </Footer>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      